.editable-input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &__value {
    padding: 1.5rem 1rem 0.375rem;
  }
  &__controls {
    padding: 1.5rem 1rem 0.375rem;
  }
}
