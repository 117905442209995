.tenant-cost-overview-new {
  font-family: 'MBCorpo Text', 'DaimlerCS-Regular', sans-serif;

  &__content {
    padding: 30px;
  }

  &__section {
    padding: 15px 0;
  }
}

.tenant-year-overview {
  &__headline {
    font-size: 1.5rem;
    background-color: silver;
    padding: 10px 30px;
    cursor: pointer;
    width: 100%;
  }

  &__costs-label {
    float: right;
  }
}

.tenant-provider-overview {
  $cell-padding: 5px 15px;

  padding: 0 30px;

  &__headline {
    font-size: 1.25rem;
    font-weight: 100;
    padding: 15px 0;
  }

  &__total-label {
    float: right;
  }

  &__table {
    width: 800px;
    min-width: 800px;
    display: block;
    border-spacing: 0;
    table-layout: fixed;
    border-collapse: collapse;
  }

  &__header-cell {
    border: 1px solid black;
    font-weight: 100;
    text-align: left;
    position: sticky;
    padding: $cell-padding;
  }

  &__body-cell {
    padding: $cell-padding;
    font-size: 0.875rem;
    border: 1px solid lightgrey;
  }
}
