.price-variants {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__table-actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.prices-form {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.price-form {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.price-variants-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
