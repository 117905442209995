.header {
  display: flex;
  color: var(--wb-white);

  &__logo {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100px;

    @include breakpoint-to(mq2) {
      width: 35px;
    }
  }

  &__image {
    transition: all 2s ease-in-out;
    opacity: 0;

    &--bigger {
      opacity: 1;
      width: 220px;
    }
  }

  &__title-container {
    position: relative;
    flex-grow: 1;
    align-items: center;
    text-align: right;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-bottom: 0.1rem;
  }

  &__title {
    @include wb-type-heading-m;
    flex-grow: 1;
  }
}

.main-menu {
  display: block;
  justify-content: flex-end;
  @include wb-spacing('margin-top', 'xxs');

  @include breakpoint-to(mq2) {
    @include wb-spacing('margin-top', 'xxs');
  }

  &--disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &__container {
    position: relative;
    text-align: right;
    display: block;
  }

  &__item {
    position: relative;
    padding: var(--wb-spacing-xxs) 15px;
    display: inline-block;

    @include breakpoint-to(mq1) {
      @include wb-spacing('padding', '3xs xxs');
    }

    &:last-of-type {
      margin-right: 0px;
      padding-right: 0px;
    }

    &-link {
      @include wb-link;
      @include wb-link--standalone;
      color: var(--wb-white);

      @include breakpoint-to(mq1) {
        font-size: 0.9rem;
      }

      &:hover {
        color: var(--wb-color-highlight);
      }
    }

    &--active {
      color: var(--wb-color-highlight);
      transition: background-size 0.42s cubic-bezier(0.165, 0.84, 0.44, 1);
      background-size: 100% 100%, 100% 100%;

      a {
        color: var(--wb-color-highlight);
        transition: background-size 0.42s cubic-bezier(0.165, 0.84, 0.44, 1);
        background-size: 100% 100%, 100% 100%;
      }
    }

    &:hover ul,
    &.over ul {
      display: block;
    }
  }

  &__submenu {
    position: absolute;
    background-color: var(--wb-grey-15);
    width: 100%;
    display: none;
  }
}
