.tenant-logs {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 150px);
  overflow-y: hidden;
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__filter {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &-col {
      @include _wb-font-text-regular;
      width: 50%;
      max-width: 340px;
    }
  }
  &__table {
    height: calc(100% - 200px);
    overflow-y: auto;
  }
  table {
    width: 100%;
  }
}
.table-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  &__buttons {
    margin-top: 10px;
    display: flex;
    flex-wrap: nowrap;
  }
  &__item {
    @include _wb-font-text-regular;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
