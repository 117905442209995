html {
  position: relative;
  min-height: 100%;
  width: 100vw;
  max-width: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  color: var(--wb-black);
  background: var(--wb-black);
  &.modal-open {
    overflow: hidden;
  }
}

.header {
  @include wb-spacing('padding', 'xs 0');
  @include wb-spacing('margin-bottom', 'xxs');
}

.body {
  @extend .wb-grid-container;
  display: grid;
  grid-template-columns: 1fr;
  align-items: start;
  align-items: stretch;
  min-height: calc(100vh - (85px + 40px));

  &__sidebar {
    grid-template-columns: 3fr 10fr;
    @include breakpoint-to(mq2) {
      grid-template-columns: 1fr;
    }
  }

  .content {
    min-height: auto;
    margin-bottom: auto;

    @include breakpoint-from(mq1) {
      @include wb-spacing('margin-left', '0');
      margin-bottom: 50px;
    }
    @include breakpoint-to(mq2) {
      @include wb-spacing('margin-top', 's');
    }
    @include breakpoint-from(mq3) {
      @include wb-spacing('margin-left', 'm');
      min-height: 550px;
      margin-bottom: 70px;
    }
  }
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  background: var(--wb-black);
  @include wb-spacing('padding', 'xs 0');
}

a {
  color: var(--wb-grey-20);
  &:hover {
    color: var(--wb-color-highlight);
  }
}

.loader-container {
  @include comp-transitions;
  text-align: center;
  @include wb-spacing('margin', 'xs 0');

  &--saving {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: auto;
    height: auto;
    background: rgba(0, 0, 0, 0.8);

    &::after {
      content: 'Saving';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200px;
      margin-left: -100px;
      margin-top: 50px;
      @include _wb-font-title-cond;
      @include wb-type-button;
      color: var(--wb-white);
      text-align: center;
    }
  }
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  margin: -16px 0 0 -16px;
  width: 32px;
  height: 32px;
  animation-name: spinanimation;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.info-icon {
  position: absolute;
  top: 1px;
  right: -20px;
  color: var(--wb-white);
  @include wb-type-copy;
  height: 16px;
  line-height: 16px;
  cursor: pointer;
}

.tooltip-layer {
  max-width: 74vw;
  width: 360px;
  padding: 20px;
  background-color: var(--wb-white);
  @include _wb-font-text-regular;
  @include wb-type-hint;
  color: var(--wb-grey-20);
  transition: opacity 0.2s;
  opacity: 1;

  &-hidden {
    opacity: 0;
    pointer-events: none;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    bottom: -8px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    margin-left: -10px;
  }

  &:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    margin-left: -8px;
    border-top-color: var(--wb-white);
    border-top-style: solid;
    border-top-width: 8px;
  }

  &-placement-top {
    &:before,
    &:after {
      left: 50%;
    }
  }

  &-placement-topLeft {
    &:before,
    &:after {
      left: 32px;
    }
  }

  &-placement-topRight {
    &:before,
    &:after {
      right: 26px;
    }
  }
}

select {
  -webkit-appearance: menulist-button;
}

.wb-button {
  &--with-icon {
    color: var(--wb-white);
    &:hover {
      color: var(--wb-color-highlight);
    }
  }

  &--with-text {
    @include wb-spacing('padding', 'xxs');
  }
}

.wb-link {
  outline: none;

  &:focus {
    outline: none;
  }
}

.wb-table {
  tbody {
    vertical-align: top;
  }
  th,
  td {
    @include wb-spacing('padding', 'xs xxs');
    line-height: 1.25rem;
    @include breakpoint-to(mq3) {
      line-height: 1.5rem;
    }
    font-size: 1rem;
  }
  &__flagged-deletion {
    color: gray;
  }
}

.multi-select {
  .dropdown-heading {
    align-items: center;
    background-color: var(--wb-grey-95);
    border: 1px solid var(--wb-grey-70);
    color: var(--wb-black);
    display: flex;
    font: inherit;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    height: 56px !important;
    line-height: 1.5rem;
    outline: none;
    width: 100%;
    border-radius: 0;
    &-value {
      span {
        @include _wb-font-text-regular;
        border-radius: 0;
      }
    }
  }
  .dropdown-content {
    @include _wb-font-text-regular;
    border-radius: 0;
  }
  .dropdown-container {
    &[aria-disabled='true'] {
      .dropdown-heading-value {
        color: #ccc;
      }
    }
  }
}

.autocomplete {
  .as__control {
    border-radius: unset;
    min-height: 56px;
    border: 1px solid var(--wb-grey-70);
    color: var(--wb-black);
    @include _wb-font-text-regular;
  }
  .as__option {
    @include _wb-font-text-regular;
  }
  .as__multi-value__label {
    @include _wb-font-text-regular;
  }
}

.memberUntilDateSelector {
  max-width: 200px;
}

@-moz-document url-prefix() {
  .memberUntilDateSelector {
    wb-icon {
      display: none;
    }
  }
}

wb-modal-content > h3:first-child {
  @include _wb-font-text-regular;
  font-size: x-large;
}

.unstiled-button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  text-align: left;
  padding: 0;
  margin: 0;
  outline: inherit;
}
