.tenants-edit-form {
  position: relative;

  &__field {
    padding: 1rem;
  }

  &__navigation {
    position: fixed;
    z-index: 1000;
    width: calc(100% - 30px);
    background-color: white;
  }

  &__container {
    max-height: calc(100% - 120px) !important;
    padding: 0 !important;
  }

  &__slide {
    width: 100%;
    padding: 0.5rem 1rem;
    transform: translateY(48px);
    min-height: calc(100vh - 96px);

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--wb-grey-85); /* color of the scroll thumb */
      border-radius: 1rem;
    }
  }

  &__main-button {
    float: right;
    padding: 0 1rem;
  }
}

.tenants-edit-modal {
  $width: 100vw;
  $height: 100vh;

  padding: 0;

  .wb-modal__outer[role='dialog'] {
    height: $height;

    @media (min-width: map-get($wb-breakpoints, 'mq4')) {
      background-color: red;
      width: $width;
      max-width: $width;
      height: $height;
      max-height: $height;
    }
  }
}

.edit-form-navigation {
  padding: 1rem;

  &__button {
    &:hover {
      &:not([disabled]) {
        color: white;
        background-color: black;
      }
    }

    &--selected {
      color: white;
      background-color: black;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.member-list__card {
  cursor: pointer;
}

.members-content {
  &__item {
    position: relative;
    &--expired {
      .members-until {
        color: red;
      }
    }
    &__row {
      &--red {
        color: red;
      }
      wb-tag {
        display: flex;
        align-items: center;
      }
    }
    &--highlight {
      border: 1px solid lightgrey;
      padding: 0.5rem;
      padding-top: 1.3rem;
    }
    &--flex {
      display: flex;
      & > div {
        flex: 1;
      }
    }
    &--black {
      color: var(--wb-list-color-text) !important;
    }
    &--scroll-container {
      max-height: 160px;
      overflow-y: auto;
      ul {
        padding-left: 20px !important;
      }
    }
  }

  &__badge {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    padding: 0.25rem;
    wb-tag {
      background-color: #008a00;
      color: white;
    }
  }
}
