/* vars */
@mixin comp-transitions {
  transition: opacity 300ms;

  &-enter {
    opacity: 0.01;

    &-active {
      opacity: 1;
    }
  }

  &-exit {
    opacity: 1;

    &-active {
      opacity: 0.01;
    }
  }
}

@mixin text--error {
  @include _wb-font-text-regular;
  color: var(--wb-color-error);
}
