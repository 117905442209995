.wh-table {
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  table {
    width: 100%;

    td {
      white-space: nowrap;
    }
    td:nth-child(6) {
      white-space: normal;
    }
  }
  &__pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__controls {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    &__inputs {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    wb-icon {
      height: auto;
      width: auto;
    }
    svg {
      height: 16px;
      width: 16px;
    }
  }
  &__action-items {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &-draft > td {
    color: var(--wb-grey-60);
  }
  &-enabled td:first-child {
    color: var(--wb-green-45);
  }
  &-failed td:first-child {
    color: var(--wb-red-45);
  }
}

.wh-github-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  &__url {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
  .error {
    border-color: red;
  }
}

.wh-form {
  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.checkbox label {
  font-weight: bold;
}

.wh-error {
  color: var(--wb-red-45);
}
.wh-target {
  width: 90%;
}
