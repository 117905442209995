.nav {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 40px;

  &--admin {
    padding-top: 0;
    height: 100%;
  }

  &--disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &-actions {
    @include comp-transitions;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60px;
  }

  &-actions-bottom {
    text-align: right;
    margin-top: 1rem;
  }

  &-filter {
    display: flex;
    justify-content: flex-end;
  }
  &-input {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    @include wb-spacing('margin-bottom', 'xxs');
    @include comp-transitions;
    &__container {
      display: flex;
      flex-direction: column;
    }
    &__top {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-start;
    }
    &__wrapper {
      width: calc(100% - 60px);
    }
  }

  &-dropdown {
    @include comp-transitions;
    @include wb-spacing('margin-bottom', 'xs');

    option.parent {
      display: none;
    }
  }

  &-inputIcon {
    top: -50px;
    right: 0px;
    color: var(--wb-grey-60);
  }

  &-list {
    position: absolute;
    overflow: hidden;
    overflow-y: auto;
    top: 175px;
    content: ' ';

    min-width: 100%;
    height: calc(100% - 270px);
    @include wb-spacing('padding-right', 'xxs');
    @include comp-transitions;

    &__item {
      @include wb-spacing('margin-top', 'xxs');
      padding: 0;
      line-height: 1rem;
      &--active {
        .wb-link--small.wb-link--theme-dark {
          color: var(--wb-blue-60);
        }
      }

      &--status {
        button::before {
          content: ' ';
          float: left;
          margin-top: 7px;
          margin-right: 5px;
          width: 10px;
          height: 10px;
          border-radius: 50%;

          @include breakpoint-to(mq2) {
            margin-top: 7px;
            margin-right: 3px;
          }
        }

        &-active ::before {
          background: #67b633;
        }
        &-idle ::before {
          background: #fdc602;
        }
        &-archived ::before {
          background: var(--wb-grey-60);
        }
      }
    }

    // sidebar without form
    &--pushUp {
      top: 55px;
    }

    &__child {
      margin-left: 8px;
    }

    &__folder {
      @include wb-type-heading-m;
      color: var(--wb-white);
      @include wb-spacing('margin', 'xs 0');
    }
  }
}
