.tenant {
  &-actions {
    display: flex;
    justify-content: flex-end;
    min-height: 52px;

    &--top {
      @include wb-spacing('margin-bottom', '3xs');
    }
    &--bottom {
      @include wb-spacing('margin', 'xxs 0');
    }

    &__title {
      flex-grow: 1;
      @include wb-type-heading-l;
      color: var(--wb-white);
      text-transform: uppercase;
      @include breakpoint-from(mq2) {
        line-height: 3rem;
      }
    }

    &__buttons {
      display: flex;

      &-button {
        @include wb-spacing('margin-left', 'xxs');
      }

      .edit,
      .save {
        height: 40px;
        min-width: 80px;
        @include wb-spacing('margin-left', 'xxs');
      }
    }
  }

  &-body {
    &__table {
      grid-template-columns: 1fr;
      overflow-x: auto;
      @include wb-spacing('padding-right', 'xxs');

      @include breakpoint-to(mq3) {
        @include wb-type-copy-secondary;
        @include wb-spacing('padding-top', 'xs');
      }

      &-label {
        text-align: left;
      }

      .wb-table {
        @include wb-spacing('margin', 'xxs 0 s 0');
      }
    }
  }
}

.tenant,
.page {
  &-content {
    @include comp-transitions;
  }

  &-header {
    display: flex;
    justify-content: flex-end;
    min-height: 60px;
    @include wb-spacing('margin-bottom', '3xs');

    &__title {
      flex-grow: 1;
      @include wb-type-heading-l;
      color: var(--wb-white);
      text-transform: uppercase;
    }

    &__buttons {
      display: flex;

      &-button {
        @include wb-spacing('margin-left', 'xxs');
      }
    }
  }

  &-body {
    background: var(--wb-white);
    display: grid;

    &__h2 {
      @include wb-type-heading-s;
      @include wb-spacing('padding', 'xs 0');
    }

    &__h3 {
      @include wb-type-heading-xs;
      @include wb-spacing('padding', 'xxs 0');
    }

    &__h4 {
      @include wb-type-copy-strong;
      @include wb-spacing('padding', 'xxs 0');
    }

    &__margin-bottom-s {
      @include wb-spacing('margin-bottom', 's');
    }

    &__message_box {
      @include wb-spacing('margin', '56px 0 xs 0');
      @include wb-spacing('padding', 'm s');
      @include wb-type-copy;
      text-align: center;

      &-error {
        @include text--error;
      }
    }

    &__section {
      @include wb-spacing('padding', 'xs xs');
      background: var(--wb-grey-85);
      overflow-x: auto;

      &--block {
        display: grid;
        grid-template-columns: 3fr 9fr;
        @include wb-spacing('column-gap', 'xs');
        @include wb-spacing('padding', '1rem xs');
        @include breakpoint-to(mq3) {
          grid-template-columns: 1fr;
        }

        &:nth-child(even) {
          background: var(--wb-white);
        }
      }

      &:nth-child(even) {
        background: var(--wb-white);
      }
    }

    &__block {
      display: grid;
      grid-template-columns: 2fr 11fr;
      @include wb-spacing('column-gap', 'xs');
      @include wb-spacing('padding', '1rem xs');

      @include breakpoint-to(mq3) {
        grid-template-columns: 1fr;
      }

      &:last-of-type {
        @include wb-spacing('padding-bottom', 'm');
      }

      &-label {
        color: var(--wb-grey-60);
        @include wb-type-label;
        text-align: left;
        margin-top: 2px;

        @include breakpoint-from(mq4) {
          text-align: right;
        }
      }

      &-value {
        @include wb-type-copy-secondary;
      }

      &-inner-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        overflow: auto;
        font-size: 0.9rem;

        @include breakpoint-to(mq3) {
          grid-template-columns: 1fr;
          @include wb-type-copy-secondary;
          @include wb-spacing('padding-top', 'xs');
        }

        .tenant-body__block {
          @include wb-spacing('padding', '3xs 0');
          grid-template-columns: 2fr 1fr;

          @include breakpoint-to(mq3) {
            grid-template-columns: 1fr;
          }

          &-label {
            @include wb-type-hint;
            color: var(--wb-grey-45);
            margin-top: 6px;
          }
        }
      }
    }

    > .tenant-body__block:nth-of-type(even),
    > .page-body__block:nth-of-type(even) {
      background: var(--wb-grey-90);
    }
  }
}

.members {
  @include wb-spacing('padding-top', 'xs');

  &-content {
    @include wb-spacing('grid-gap', 'xs xxs');

    &__item {
      &-label {
        color: var(--wb-grey-60);
        @include wb-type-label;
        margin-top: 2px;
        margin-right: 10px;
      }
      a,
      p {
        @include wb-type-label;
      }
      &__row {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
      }
    }
  }
}

.costoverview {
  &-content {
    table {
      border-spacing: 0;
      width: 100%;
    }
    &__h3 {
      @include wb-type-copy-strong;
      @include wb-spacing('padding', '0 0 xs 0');
    }

    &__header {
      display: flex;
      background: var(--wb-grey-20);
      @include wb-spacing('padding', 'xxs');
      @include wb-type-copy-strong;
      color: var(--wb-white);
      border-bottom: 0;

      &-admin {
        background: none;
        color: inherit;
      }
      &-title {
        flex-grow: 1;
      }
      &-total {
        float: right;
      }
    }

    &__bottom {
      &-total {
        @include wb-type-copy-tertiary;
      }
    }

    &__provider-title {
      @include wb-type-copy-strong;
    }
    .wb-table {
      margin-bottom: 0;

      &--border-vertical td,
      &--border-vertical th {
        margin-left: -1px;
        margin-right: -1px;
      }
    }
  }
}

.costoverview__table-body {
  &--collapsed {
    display: none;
  }
}

.costoverview__table-expand {
  width: 12px;
  height: 12px;
  margin: 0 0.75rem;
}

.new,
.save {
  background-color: var(--wb-color-highlight);
  color: #fff;
  border: 1px solid var(--wb-color-highlight--outline-dark);

  &:hover:not([disabled]) {
    background-color: var(--wb-color-highlight--hover);
    transition: background-color 0.3s ease-in-out;
  }
}
