.tenant-dialog {
  & > [role='dialog'] {
    width: 100vw !important;
    height: calc(100vh - 100px);
    max-width: unset !important;
    max-height: unset;
    padding-top: 0;
    wb-modal-content {
      max-height: 80% !important;
    }
  }
  & > [mode='prompt'] {
    padding-top: 0;
  }

  &__content {
    h3 {
      z-index: 30;
      padding: 10px;
      width: calc(100% - 40px);
      background-color: white;
      max-height: unset !important;
      border-bottom: 1px solid black;
    }
    & > div {
      padding-top: 60px;
    }
  }

  &__footer {
    position: absolute !important;
    justify-content: end;
  }
}

.booking-permissions {
  &__head {
    display: flex;
    justify-content: flex-end;
    .wb-button--secondary {
      margin-right: 1rem;
    }
  }
  .permissions-selector {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .permissions-editor {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &__permission {
      height: 3.6rem;
      width: 9rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__controls {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      button {
        margin-left: 1rem;
      }
    }
  }
}
