.wb-m-lightbox-2__close {
  font-size: 16px;
  line-height: 20px;
}

.modal_fx {
  transition: opacity 300ms;
  display: flex;

  &-enter {
    opacity: 0.01;

    &-active {
      opacity: 1;
    }

    &-done {
      opacity: 1;
    }
  }

  &-exit {
    display: flex;
    opacity: 1;

    &-active {
      display: flex;
      opacity: 0.01;
    }
  }
}
