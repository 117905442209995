.backoffice-bookings {
  &__header {
    display: flex;
    align-content: center;
    align-items: space-between;
  }
  &__header-left {
    flex: 2;
  }
  &__header-right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
