.footer {
  &__text-with-image {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  &__text {
    @include wb-type-copy-secondary;
    color: var(--wb-white);
  }

  &__mbio {
    max-height: 20px;
    @include wb-spacing('margin-left', 'xxs');
    margin-bottom: 3px;
  }
}
