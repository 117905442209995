.permission-item {
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    button {
      margin: 0 5px;
    }
  }
}

.service-form {
  &__info {
    color: black;
    font-size: 30px;
    margin: 15px;
    cursor: pointer;
    width: fit-content;
  }
}

.permissions-list {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
