.service-property-form {
  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__select-generator {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__input-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 54px;
  }
}
