$animationTime: 2s;

.mainpage-header {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px 0px 50px 0px;
  &__left {
    flex: 1;
    text-align: center;
    & img {
      width: 200px;
      height: 120px;
    }
  }
  &__right {
    flex: 1;
    text-align: center;
    & button {
      border-radius: 2px;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0.025em;
      line-height: 1.5rem;
      text-align: center;
      background-color: #00adef;
      border: 1px solid #4a90e2;
      color: #ffffff;
      min-width: 144px;
      outline: none;
      padding: 7px 15px;
    }
  }
}
.mainpage-main {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: black;
  color: white;
}

.message h1 {
  margin: 40px 0 20px;
}

.mainpage-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 10px;
}

.lock {
  border-radius: 5px;
  width: 55px;
  height: 45px;
  background-color: #333;
  animation: dip 1s;
  animation-delay: ($animationTime - 0.5);

  &::before,
  &::after {
    content: '';
    position: absolute;
    border-left: 5px solid #333;
    height: 20px;
    width: 15px;
    left: calc(50% - 12.5px);
  }

  &::before {
    top: -30px;
    border: 5px solid #333;
    border-bottom-color: transparent;
    border-radius: 15px 15px 0 0;
    height: 30px;
    animation: lock $animationTime, spin $animationTime;
  }

  &::after {
    top: -10px;
    border-right: 5px solid transparent;
    animation: spin $animationTime;
  }
}

.mainpage-content,
.lock,
.message {
  position: relative;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@keyframes lock {
  0% {
    top: -45px;
  }
  65% {
    top: -45px;
  }
  100% {
    top: -30px;
  }
}

@keyframes spin {
  0% {
    transform: scaleX(-1);
    left: calc(50% - 30px);
  }
  65% {
    transform: scaleX(1);
    left: calc(50% - 12.5px);
  }
}

@keyframes dip {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
