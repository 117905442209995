@import '~@workbench/core/dist/scss/extensions/spacing.scss';

:root {
  --gutter: 20px;
}
.admin-panel {
  &-grid {
    display: grid;
    grid-gap: var(--gutter);
    margin: var(--gutter) 0;
    overflow: scroll;
    max-height: 80vh;

    &-column {
      background: #fff;
      display: grid;
      grid-template-columns: 100%;
    }
  }
}
.timereport-msg {
  @include wb-spacing('margin', 'xs 0');
}
.timereport-header {
  background-color: rgb(51, 51, 51);
  color: #fff;
  padding: 10px;
  @include wb-type-copy-secondary;
}
.timereport-column-info {
  display: grid;
  padding: 10px;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  @include wb-type-copy-secondary;

  h3 {
    @include wb-spacing('margin', '10px 0');
    @include wb-type-copy-strong;
  }
}

wb-datepicker-control.timeline-tracker wb-datepicker {
  position: relative;
  top: 10px;
}
