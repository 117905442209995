.tenant-members {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  &__header {
    &__bulk-actions {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  &__user-info {
    height: 56px;
    line-height: 56px;
  }
  &__header-left {
    display: flex;
  }
  &__header-right {
    max-width: 255px;
  }
  &__row {
    display: flex;
    align-items: center;
  }
  &__expiration-filter {
    width: 232px;
    margin-left: auto;
    padding-right: var(--wb-spacing-xxs);
  }
  &__header-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 160px;
  }
  &--user-expired {
    color: red;
  }
  &__body-table {
    width: 100%;
  }
  table tbody td:first-child,
  table tbody td:last-child {
    vertical-align: middle;
  }
  &__roles-form,
  &__labels-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 300px;
    min-width: 200px;
    .autocomplete {
      width: 100%;
    }
    .as__menu {
      width: max-content;
    }
    wb-select-control {
      max-width: 80%;
      width: 300px;
    }
    button {
      min-width: 40px;
    }
  }

  &__tagged_roles,
  &__tagged_labels {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }
  &__action-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100px;
  }
  table td {
    vertical-align: top;
  }
  &-dialog__footer {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    background: var(--wb-white);
    margin: 24px;
  }
}
