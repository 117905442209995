.tdms-users {
  &__table-head {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  &__left-head {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &__right-head {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__multiselect {
    max-width: 240px;
    width: 240px;
  }
  table tr td {
    vertical-align: middle;
    white-space: nowrap;
  }
}

.tdms-user-form {
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
}
