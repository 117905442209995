.tenants-edit {
  width: 100%;
  $form-padding: 20px 24px;

  margin-bottom: 0;
  @include wb-type-copy;

  #root {
    margin: 0;

    &__title,
    &__description {
      display: none;
    }
  }

  &__form {
    width: 100%;
    position: relative;
    float: left;
    padding: 0 0 70px 0;
    color: var(--wb-white);
    @include wb-type-copy;

    .col-xs-9 {
      @extend .wb-grid-col-mq1-9;
      float: left;
    }

    .col-xs-3 {
      @extend .wb-grid-col-mq1-3;
      float: left;
    }

    > .form-group {
      > .header,
      > label {
        display: none;
      }

      > fieldset > .form-group {
        padding: $form-padding;
        background: var(--wb-grey-15);

        &:nth-child(even) {
          background-color: var(--wb-grey-20);
        }
        @include breakpoint-from(mq1) {
          &.even {
            background-color: var(--wb-grey-20);
          }

          &.odd {
            background: var(--wb-grey-15);
          }

          &.hilited {
            background: var(--wb-grey-40);
          }
        }
      }

      .disabledMessage {
        color: var(--wb-grey-60);
      }

      .help-block {
        @include wb-type-copy-secondary;
        @include wb-spacing('padding', 'xs 0');
      }
    }

    .hidden,
    legend,
    .field-array-of-object > .info-icon,
    .field-array-of-string > .info-icon {
      display: none;
    }

    .info-icon {
      right: -25px;
      line-height: 0.875rem;
      font-size: 0.875rem;
      width: 16px;
      height: 16px;
      border: 1px solid;
      border-radius: 50%;
      text-align: center;
    }

    .header {
      display: block;
      padding: 2px 0 8px 0;
      line-height: 16px;

      .control-label {
        display: inline-block;
        @include wb-type-label;
        height: 16px;
        line-height: 16px;

        &--tooltip {
          position: relative;
        }
      }
    }

    .radio-inline,
    .checkbox-inline {
      position: relative;
      display: inline-block;
      margin: 0 0 6px 0;
      padding-left: 20px;
      vertical-align: middle;

      &:not(:last-child) {
        margin-right: 16px;
      }

      input {
        position: absolute;
        left: 0;
        top: 0;
        width: 16px;
        min-width: auto;
        height: 24px;
        margin: 2px 0;
        padding: 0;
        background: transparent;
        @include wb-type-copy-secondary;
      }
    }

    label {
      max-width: 100%;
    }

    input {
      background-color: #fff;
      background-image: none;
      border-radius: 1px;
      border: 1px solid #999;
      color: #333;
      display: block;
      height: 35px;
      line-height: 1;
      padding: 0 14px;
      width: 100%;
      @include wb-type-copy-secondary;

      &:disabled {
        background: var(--wb-grey-60);
      }

      &:focus {
        box-shadow: none;
        outline: none;
        border-color: var(--wb-color-highlight);
      }
    }

    textarea {
      background-color: #fff;
      border-radius: 1px;
      color: #333;
      display: block;
      height: 200px;
      margin-top: 9px;
      padding: 8px 14px 0;
      width: 100%;
      border: 1px solid #999;
      @include wb-type-copy-secondary;

      &:disabled {
        background: var(--wb-grey-60);
      }
      &:focus {
        box-shadow: none;
        outline: none;
        border-color: var(--wb-color-highlight);
      }
    }

    select {
      width: 100%;
      min-width: 80px;
      height: 35px;
      @include wb-type-copy-secondary;

      display: block !important;
      opacity: 1 !important;
      position: relative !important;
    }

    .btn-group {
      justify-content: flex-start !important;
    }

    .btn {
      border: none;
      border-radius: 1px;
      cursor: pointer;
      float: left;
      height: 35px;
      margin-right: 10px;
      outline: none;
      padding: 7px 14px 8px;
      text-decoration: none;
      background-color: #ececec;
      color: #333;
      margin-top: 0;
      font-weight: 400 !important;

      &:not([type='submit']),
      &:not(.array-item-remove) {
        font-size: 22px;
        line-height: 20px;
      }

      &-add {
        margin-top: 24px;
        max-width: 54px;

        &:before {
          content: '+';
          font-size: 32px;
          line-height: 20px;
        }
      }

      &-danger,
      &-danger:focus,
      &-danger:hover,
      &-danger:active {
        color: #eb2220;
      }

      &.array-item-move-up:before {
        content: '\2191';
      }

      &.array-item-move-down:before {
        content: '\2193';
      }

      &.array-item-remove:before {
        content: '\2715';
      }

      .glyphicon {
        display: none;
      }
    }

    .array-item {
      display: block;
      float: left;
      width: 100%;
      margin-top: 6px;
      padding: 12px 0;

      .form-group.field-string {
        position: relative;
        padding-top: 8px;

        .searchBtn {
          position: absolute;
          top: 42px;
          right: -62px;
          width: 48px;
          margin: 0;
          text-align: center;
        }
      }

      .btn {
        max-width: 48px;
        margin: 0 0 0 12px;
      }
    }

    &-group--half {
      height: 136px;
      overflow: hidden;

      input,
      textarea,
      select {
        width: 50%;
        min-width: 80px;
      }
    }

    @include breakpoint-from(mq1) {
      &-group--half {
        float: left;
        width: 50%;
      }
    }
  }

  &__experiences {
    .checkbox-inline {
      display: block;
    }
  }

  &__personalData {
    width: 100%;
    height: 100px;
    float: none;
  }

  &__nebulaControllingInformation {
    .header {
      margin-bottom: 25px;
    }

    input.form-control {
      margin-bottom: 10px;
    }

    p.field-description {
      display: none;
    }
  }

  &__gbsControllingInformation {
    .header {
      margin-bottom: 25px;
    }

    input.form-control {
      margin-bottom: 10px;
    }

    p.field-description {
      display: none;
    }
  }

  &__b2xCoreControllingInformation {
    .header {
      margin-bottom: 25px;
    }

    input.form-control {
      margin-bottom: 10px;
    }

    p.field-description {
      display: none;
    }
  }

  &__members {
    clear: both;

    fieldset {
      min-width: 0;
    }

    .field-object > .header {
      display: none;
    }

    .col-xs-9 {
      width: 64% !important;
    }

    .col-xs-3 {
      width: 36% !important;
    }

    .array-item {
      @extend .tenants-edit__form-group--half;
      padding: 12px;
      height: auto;
      min-height: 240px;
      overflow: visible;

      input,
      select {
        width: 100%;
      }
    }
    .array-item-list {
      margin: 0 -12px;
    }

    @include breakpoint-from(mq4) {
      .array-item {
        width: 33%;
      }
    }
    .array-item-toolbox {
      padding-top: 42px;
    }

    .row:not(.array-item-list) {
      clear: both;
    }

    .role-selector {
      select {
        height: 133px;
      }
    }
    .member-comment {
      min-width: 300px;
    }
  }

  &__actions {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;

    &--top {
      top: -60px;
    }

    &--bottom {
      bottom: 0;
    }

    .save {
      @include wb-spacing('margin-left', 'xxs');
    }
  }

  &__errors {
    display: block;
    padding: 12px 24px;
    background-color: var(--wb-color-error);
    &--server {
      color: var(--wb-white);
    }
  }

  &--serverErrors .tenants-edit__actions--top {
    top: -103px;
  }

  &__required-info {
    padding: $form-padding;
    font-size: 0.875rem;
    color: #00adef;
  }

  .error-detail {
    display: inline-block;
    padding: 0 1px;
    background-color: var(--wb-color-error);
  }

  .has-error {
    .control-label {
      background-color: var(--wb-color-error);
    }

    .form-control {
      border-color: var(--wb-color-error);
    }
  }

  .no-click {
    .field-radio-group {
      pointer-events: none;
      opacity: 0.625;
    }
  }
}

input[type='date'] {
  appearance: none;
  -webkit-appearance: none;

  &::-webkit-clear-button {
    display: none;
  }

  &::-webkit-inner-spin-button {
    display: none;
  }

  &::-webkit-calendar-picker-indicator {
    opacity: 1;
    cursor: pointer;
    color: var(--wb-grey-20);
  }
}

@media (min-resolution: 0) {
  .tenants-edit__form .radio-inline input,
  .tenants-edit__form .checkbox-inline input {
    left: -12px;
  }
}
