.member-view {
  background-color: white;
}

.member-form {
  &__headline {
    @include _wb-font-text-regular;

    padding: 15px;
  }

  &__content {
    padding: 15px;
    height: 100%;
  }

  &__explanation {
    @include _wb-font-text-regular;

    opacity: 0.4;
    padding: 15px;
  }
  &__modal {
    position: absolute;

    > .wb-modal__outer {
      align-self: auto !important;
    }
    wb-modal-content {
      h3 {
        @include wb-type-heading-m;
      }
    }
  }
}

.tenant-member-form {
  &__member {
    width: 16.6666%;

    @media screen and (max-width: 1024px) {
      width: 25%;
    }

    @media screen and (max-width: 800px) {
      width: 33.3333%;
    }

    &:after {
      content: '';
      display: block;
      border-bottom: 1px solid lightgrey;
      margin: 20px;
    }
  }
}

.tenant-users-dialog__content {
  overflow: visible;
}
.tenant-members-dialog {
  [role='dialog'] {
    width: 100vw !important;
    height: 92vh;
    max-width: unset !important;
    wb-modal-content {
      max-height: unset !important;
    }
  }

  &__content-main {
    height: 63vh;
    overflow-y: auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
  &__content {
    h3 {
      top: 0;
      z-index: 30;
      padding: 10px;
      width: calc(100% - 40px);
      background-color: white;
      position: absolute;
      overflow-y: hidden;
      max-height: unset !important;
    }
    & > div {
      padding-top: 60px;
    }
    height: 100%;
  }

  &__footer {
    position: absolute !important;
    justify-content: end;
  }

  &__table {
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    table.wb-table td {
      white-space: nowrap;
    }
  }
  &__actions {
    height: 180px;
    min-height: 180px;
    display: flex;
    flex-direction: row-reverse;
  }
}

.tenant-add-remove {
  margin: 30px 0;

  &__list {
    padding: 30px;

    &:after {
      content: '';
      display: block;
      border-bottom: 1px solid lightgrey;
      margin-top: 15px;
    }
  }
}

.bulk-add-users {
  wb-modal-content.hydrated {
    padding: 0;
  }
  wb-modal-footer.hydrated {
    padding: 30px 0;
  }
  &__section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }
}

.add-user-form {
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.member-apikey {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.api-table__button {
  background: none;
  border: none;
}
