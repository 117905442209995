.provider-users {
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__left-head {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &__right-head {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  table > tbody td {
    white-space: nowrap;
  }
}
.provider-details {
  width: 70%;
  dl {
    width: 100%;
    overflow: hidden;
  }
  dt {
    float: left;
    width: 50%;
    font-weight: bold;
  }
  dd {
    float: left;
    width: 50%;
  }
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.providers-table {
  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.providers-list {
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
