.genericContainer {
  &__section {
    padding: 30px 20px;
    background: var(--wb-grey-85);

    &:nth-child(even) {
      background: var(--wb-white);
    }
  }

  &__table {
    &--w100 {
      width: 100%;
    }
    &--border {
      border: 1px solid var(--wb-grey-70);

      .column {
        border: 1px solid var(--wb-grey-70);
      }
    }
    .column {
      padding: 10px 12px;
      color: var(--wb-grey-20);

      &:nth-child(odd) {
        color: var(--wb-grey-45);
      }
      &--label {
        font-size: 14px;
      }
    }

    &--zebra {
      margin-bottom: 20px;
      background: #fff;

      .column {
        font-size: 14px;
        line-height: 18px;
        vertical-align: top;

        &--dark {
          background: var(--wb-grey-20) !important;
          color: var(--wb-white) !important;
          border: 1px solid var(--wb-grey-20);
        }
        &--header {
          font-size: 18px;
          line-height: 22px;
          font-weight: 600;
        }
        &:first-child {
          background: var(--wb-white);
          font-weight: bold;
        }
        &:nth-child(2n + 3) {
          background: var(--wb-grey-85);
        }
        &:nth-child(odd) {
          color: var(--wb-grey-20);
        }
      }
    }

    &__ul {
      margin: 10px 0;
      padding-left: 22px;
      list-style-type: disc;
    }

    ul {
      margin: 10px 0;
      padding-left: 20px;
      list-style: disc outside none;

      ul {
        list-style: circle outside none;
      }
      li {
        margin: 5px 0;
      }
    }
  }

  .wb-e-acco-1 {
    margin: 0;

    .wb-e-acco-1__section {
      .wb-e-acco-1__title {
        background: #e4e4e4;
      }
    }
    .wb-e-acco-1__section--expanded {
      background: #f4f4f4;
      .wb-e-acco-1__title {
        background: #f4f4f4;
      }
    }
  }
}

.sla-overview {
  display: flex;
  flex-direction: column;
  .wb-card {
    --bg-color: var(--wb-white);
    --shadow: var(--wb-shadow-level-1);
    --color: var(--wb-black);
    --border: 1px solid transparent;
    --bg-color--hover: var(--wb-grey-95);
    --border-color--hover: var(--wb-blue-45);
    --border-color--pressed: var(--wb-blue-35);
    background: var(--bg-color);
    border: var(--border);
    -webkit-box-shadow: var(--shadow);
    box-shadow: var(--shadow);
    color: var(--color);
    display: block;
  }
  &__wrapper {
    display: grid;
    overflow: hidden;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    @include wb-spacing('grid-column-gap', 'xs');
    @include wb-spacing('grid-row-gap', 'xs');

    @include breakpoint-to(mq4) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include breakpoint-to(mq1) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__item {
    display: flex;
  }

  &__card {
    display: flex;
    flex-direction: column;
    width: 100%;

    &--content {
      flex-grow: 1;
      @include wb-spacing('margin', 'xxs 0');
    }

    &--footer {
      @include wb-spacing('margin-top', 'xs');
      text-align: right;
      align-self: flex-end;
    }
  }
}

.wb-icon {
  &--red {
    color: #e1292b;
  }
  &--green {
    color: #008a00;
  }
}

.markdown-body {
  ol,
  ul {
    list-style: disc;
  }
}
