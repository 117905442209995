.compliance-form {
  &__input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }
  &__section {
    background: #f4f4f4;
    padding: 0 !important;
  }
  &__radio-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
  }
  &__checkbox-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 100px !important;
  }
  &__hint {
    &--italic {
      font-style: italic;
      width: 70%;
    }
  }
}
