.cross-charging {
  &__sub-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 25px;
    &__start {
      flex: 10;
      font-size: 22px;
      font-weight: bold;
    }
    &__end {
      flex: 2;
    }
  }
  &__body {
    .wb-accordion__toggle {
      background-color: white !important;
    }
    .wb-accordion__toggle-inner {
      width: 100%;
    }
  }
  &__provider-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
  }
  &__provider-title {
    width: 'calc(100% - 50px)';
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__list-item {
    &__head {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      &__title {
        width: 330px;
        display: flex;
        justify-content: space-between;
        span {
          font-weight: bold;
          font-size: 1.5rem;
        }
      }
    }
  }
}
.tenant-emails {
  table {
    margin: 0;
  }
  &__form {
    legend {
      margin-bottom: 10px;
      font-weight: bold;
    }
    &__results {
      max-width: 100%;
      max-height: 300px;
      margin-bottom: 10px;
      overflow: auto;
    }
  }
}
