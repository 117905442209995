.tenant-provider-form {
  min-height: 200px;
  padding: 20px, 0px;
  margin-bottom: 50px;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  div {
    flex: 1;
  }
}
