.bookings-editor {
  &__headline {
    margin-bottom: 15px;
  }

  &__content {
    padding: 15px;
    height: 100%;
  }

  &__form-field {
    margin-bottom: 15px;

    textarea {
      height: 300px;
      font-family: monospace;
    }

    &--sm {
      textarea {
        height: unset;
      }
    }
  }

  &__modal {
    &[role='dialog'] {
      width: 100%;
    }
  }
}

.full-width-modal {
  .wb-modal__outer {
    max-width: unset;
    width: 100% !important;
  }
}

.packages-editor {
  &__list {
    margin: 25px 0;
    overflow: auto;
    padding-right: 10px;
    @include _wb-font-text-regular;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  &__list-item {
    cursor: pointer;
    padding: 0.5rem;

    &--active {
      font-weight: bold;
      color: #00adef;
      border: 1px solid black;
    }
    &--archived {
      color: #a69f9f;
    }
    &--disabled {
      color: #a69f9f;
    }
  }

  &__list-item-description {
    font-size: 0.75rem;
    opacity: 0.5;
  }

  &__button {
    margin: 5px 5px 5px 0;
  }
}

.package-prices-editor {
  &__current-wrapper {
    @include _wb-font-text-regular;
  }

  &__current-list {
    padding-bottom: 30px;
  }

  &__current-title {
    padding: 15px 0;
  }

  &__form-title {
    @include _wb-font-text-regular;
    margin: 20px 0;
  }
}

.package-list {
  &__price-list {
    font-weight: 400;
    padding: 15px;
    opacity: 0.5;
    font-size: 0.75rem;
  }

  &__price-list-title {
    font-weight: normal;
    margin-bottom: 10px;
  }

  &__price-list-table {
    font-family: monospace;
    td {
      padding: 5px 10px;
      border: 1px solid lightgrey;
    }
    &--selected {
      background-color: var(--wb-blue-45);
      color: white;
    }
  }

  &__price-value {
    text-align: right;
  }
}

.packages-manager {
  display: flex;
  justify-content: space-between;

  & > div {
    width: calc(50% - 25px);
    height: 100%;
  }
}

.booking-dialog {
  div[role='dialog'] {
    width: 100%;
    height: 100%;
  }

  &__headline {
    margin: 15px 0;
  }

  &__content {
    overflow: visible;
  }
}

.bookings-list {
  @include _wb-font-text-regular;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 8px;
  &__item {
    border: 1px solid #d3d3d3;
    margin: 8px;
    padding: 1rem;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    &--expired {
      background-color: #fbefef;
    }

    @media screen and (min-width: 800px) {
      width: calc(50% - 16px);
    }

    @media screen and (min-width: 1200px) {
      width: calc(33.3333% - 16px);
    }

    @media screen and (min-width: 1600px) {
      width: calc(25% - 16px);
    }

    &--active {
      border: 1px solid black;
    }
  }
  &__item-actions {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

.booking-form__actions {
  display: flex;
  justify-content: end;
}

.package-details {
  dl {
    width: 100%;
    overflow: hidden;
  }
  dt {
    float: left;
    width: 50%;
  }
  dd {
    float: left;
    width: 50%;
  }
}

.package-services__list {
  label .wb-checkbox-control__checkmark-icon {
    margin-top: 15px;
  }
}
